<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="notice_detail"
      space="48"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex flex-column pr-6"
            >
              <span class="text-lg-h5">
                {{ notInfo.title }}
              </span>
              <div class="d-flex flex-row justify-space-between align-center mt-3">
                <land-subtitle>
                  {{ notInfo.createTime }}
                </land-subtitle>
                <span class="text-subtitle-1 blue-grey--text lighten-3">
                  <v-icon
                    color="blue-grey lighten-3"
                  >mdi-eye-outline</v-icon>
                  <span class="pl-2">{{ notInfo.hits }}</span>
                  <!-- <v-icon
                    color="blue-grey lighten-3"
                  >mdi-thumb-up-outline</v-icon>
                  <span class="pl-2 pr-4">{{ notInfo.praise }}</span>
                  <v-icon
                    color="blue-grey lighten-3"
                  >mdi-cards-heart-outline</v-icon>
                  <span class="pl-2">{{ notInfo.collect }}</span> -->
                </span>
              </div>

              <div
                class="d-flex flex-column"
              >
                <div
                  v-if="notPrev.noticeId"
                  class="d-flex flex-row mt-4"
                >
                  <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">上一篇:</span>
                  <a
                    class="text-subtitle-1 red--text"
                    @click="toNotice(notPart, notPrev)"
                  >
                    {{ notPrev.title }}
                  </a>
                </div>
                <div
                  v-if="notNext.noticeId"
                  class="d-flex flex-row mt-4"
                >
                  <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">下一篇:</span>
                  <a
                    class="text-subtitle-1 red--text"
                    @click="toNotice(notPart, notNext)"
                  >
                    {{ notNext.title }}
                  </a>
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="hidden-sm-and-down"
          >
            <div
              v-for="(part, partIndex) in sideParts"
              :key="`part_${partIndex}`"
              class="d-flex flex-column"
            >
              <land-navigation
                :title="part.title"
                :items="part.buttons"
                size="text-lg-h6"
                :margin="`${partIndex > 0 ? 'mb-2 mt-6' : 'mb-2'}`"
                color="red--text"
                @click="toNotices($event, part)"
              />
              <land-informs
                :items="part.items"
                @click="changeNotice($event, part)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import cookies from '@/api/co.libs.cookies'

  import mixPartyBanner from '@/pages/mixins/party/mix.party.banner'
  import mixPartyNotice from '@/pages/mixins/party/mix.party.notice'

  import partyPreset from '@/pages/mixins/party/mix.party.preset'

  export default {
    metaInfo: { title: '党建园地' },
    mixins: [mixPartyBanner, mixPartyNotice],
    data () {
      return {}
    },
    created () {
      const name = api.page.partyNotice.name
      const nav = JSON.parse(cookies.get(name))
      Object.assign(this.navMeta, nav)

      this.initBannerParams({
        siteId: app.init.siteId,
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      const part = partyPreset.Parts[nav.name] || {}
      Object.assign(this.notPart, part)

      this.setCrumbs(part, false)
      this.configSide([nav.name])

      this.getNotice({
        noticeId: nav.noticeId
      })
      this.getSibing({
        noticeId: nav.noticeId
      })
    },
    methods: {
      changeNotice (ev, part) {
        this.toNotice(part, ev.item)
      }
    }
  }
</script>
